import React from 'react';
import { connect } from 'react-redux';
import '../search-panel/search-panel.scss';
import { getTranslate } from 'react-localize-redux';
import { Card, Col} from 'react-bootstrap';
import StateDropdown from "../state-dropdown/state-dropdown.component";

const SpecifyPanel = ({ translate }) => {

    return(
        <Col xs={{order: 12}} sm={{order: 12}} md={{order: 12}} lg={{order: 1}} className="d-flex justify-content-center align-items-center">
            <Card className="text-center" aria-live="polite" id="specify-panel">
                <Card.Header as="h1">{translate("specify-panel.header")}</Card.Header>
                <Card.Body>
                    <Card.Text>
                        {translate("specify-panel.comment")} 
                    </Card.Text>
                    <StateDropdown/>
                </Card.Body>
            </Card>
        </Col>
    );
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
  })
  

  export default connect(mapStateToProps)(SpecifyPanel);