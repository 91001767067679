import React, { Component } from 'react';
import { Form, FormControl, InputGroup, Button} from 'react-bootstrap';
import DropdownOptions from "./dropdown-options";

import { searchGeography } from '../../redux/search/search.actions';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import '../search-bar/search-bar.scss';
import { LinkContainer } from "react-router-bootstrap";

class StateDropdown extends Component {
    constructor(props) {
          super(props);
  
          this.state = {
              searchterm: this.props.searchTerm,
              selection: "",
            };
          this.onInputChange = this.onInputChange.bind(this);
          this.onSelectionChange = this.onSelectionChange.bind(this);
          this.onEnter = this.onEnter.bind(this);
          this.search = this.search.bind(this);
    }
    
    componentDidUpdate(prevProps){
        if (this.props.searchTerm !== prevProps.searchTerm){
        
                this.setState({
                    searchterm: this.props.searchTerm
                })
            }
      }
    
    onInputChange(event){
		this.setState({ searchterm: event.target.value });
    }
    
    onSelectionChange(event){
        this.setState({ selection: event.target.value });
    }

    onEnter(target){
        if(target.charCode===13){
            target.preventDefault();
            this.search();
        }
    }

    search(){
        let newSearch = this.state.searchterm;
        
        //Check if already added.
        if(this.state.selection !== ""){
            let i = this.state.selection.indexOf(',')
            if(i > -1){
                newSearch = newSearch.substring(0, i) + ", " + this.state.selection
            }
            else 
            {
                newSearch = newSearch + ", " + this.state.selection
            }
            
        }
        this.props.searchGeography(newSearch);
    }

   
        render() {
            
            return(
                    <Form inline className="search-bar">
                        <InputGroup>
                            <FormControl
                                onChange={this.onInputChange}
                                onKeyPress={this.onEnter}
                                value={this.state.searchterm}
                                type="text"
                                aria-label="Search"
                                aria-describedby="specify-panel"
                                aria-invalid={this.props.showGeography === 2 ? "true" : "false"}
                                placeholder={ this.props.translate("search-bar.placeholder") }
                            />
                            <FormControl
                                onChange={this.onSelectionChange}
                                onKeyPress={this.onEnter}
                                value={this.state.selection}
                                placeholder={ this.props.translate("specify-panel.placeholder") }
                                as="select"
                                aria-label="Select a state"
                            >
                                <DropdownOptions/>
                            </FormControl>
                            
                            <LinkContainer to="/search"> 
                                <Button 
                                    variant="primary"
                                    active
                                    id="search-button"
                                    size="sm"
                                    type="submit"
                                    role="button"
                                    aria-label="Search a location to find health department travel guidance."
                                    onClick={()=>{this.search()}}
                                    >{ this.props.translate("search-bar.search") }
                                </Button>
                            </LinkContainer>
                        </InputGroup>
                    </Form>

            );
        };
    };

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    searchTerm: state.search.searchTerm,
    showGeography: state.search.showGeography,
})

 export default connect(mapStateToProps, { searchGeography })(StateDropdown);
