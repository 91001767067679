import React from 'react';
import { Card } from 'react-bootstrap';
import { getTranslate } from 'react-localize-redux';

import { connect } from 'react-redux';

const Loading = ({ searchTerm }) => {
    return(
        <Card aria-live="polite" id="loading">
            <Card.Body>
                <Card.Text>Loading guidance</Card.Text>
            </Card.Body>
        </Card>)
}

const mapStateToProps = state => ({
    searchTerm: state.search.searchTerm,
  })

export default connect(mapStateToProps)(Loading);