import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage, setActiveLanguage, Translate } from "react-localize-redux";
import SearchBar from "../search-bar/search-bar.component";
import './nav-bar.scss';
import { useState}from 'react';
const CustomNavBar = ({ showGeography, setActiveLanguage, activeLanguage }) => {
    const [show, setShow] = useState(false);

         setTimeout(() => {
    
         setShow(!show);
    
     }, 1000);
      return (
            <header>
                <Navbar className="fixed-height justify-content-lg-center" variant="dark" expand="lg" id="nav-elements">
                    <Nav>
                    
                        {  showGeography === 1 && 
                                <SearchBar />
                            }
                    </Nav>
                    &nbsp;&nbsp;
                    <h2 style={{color: (show ?"red":"white")}}></h2>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link aria-label="For health departments" target="_blank" href="https://tbcarefinderadmin.cdc.gov"><Translate id="nav-bar.health-department" /></Nav.Link>
                            <Nav.Link aria-label="Translate language"
                                onClick = {() => { activeLanguage.code === "es" ? setActiveLanguage("en") : setActiveLanguage("es") }}
                                ><Translate id="nav-bar.language" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
              
      );
  }

  const mapStateToProps = state => ({
    showGeography: state.search.showGeography,
    activeLanguage: getActiveLanguage(state.localize),
})


export default connect(mapStateToProps, { setActiveLanguage })(CustomNavBar);
