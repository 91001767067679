import React from 'react';
import { connect } from 'react-redux';
import SearchBar from "../../components/search-bar/search-bar.component";
import './search-panel.scss';
import { getTranslate } from 'react-localize-redux';
import { Card, Col} from 'react-bootstrap';

const SearchPanel = ({ translate }) => {

    return(
        <Col xs={{order: 12}} sm={{order: 12}} md={{order: 12}} lg={{order: 1}} id="search-panel" className="d-flex justify-content-center align-items-center">
            <Card className="text-center search-panel-message" aria-live="polite">
                <Card.Header as="h1">{ translate("landing-page.header") }</Card.Header>
                <Card.Body>
                    <Card.Text>
                        { translate("landing-page.text1") }
                        <br/>
                        { translate("landing-page.text2") }
                    </Card.Text>
                    <SearchBar/>
                </Card.Body>
            </Card>
        </Col>
    );
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
  })
  

  export default connect(mapStateToProps)(SearchPanel);