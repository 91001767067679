import { createStore, applyMiddleware } from 'redux';

//import logger from 'redux-logger';

import rootReducer from './root-reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';


//const middlewares = [logger, thunk];

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
    //window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ));

export default store;