import axios from 'axios';
import { ROOT_URL } from '../../components/utils';

const ROOT_URL_DB = ROOT_URL

const NYC = ['Manhattan', 'Queens', 'Brooklyn', 'Staten Island', 'The Bronx', 'Bronx County', 'Kings County', 'Queens County', 'New York County'];


// Helper functions to search database f
const contains = (arr, v) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === v) return true;
  }
  return false;
};

const unique = (list) => {
  var arr = [];
  for (var i = 0; i < list.length; i++) {
    if (!contains(arr, list[i])) {
      arr.push(list[i]);
    }
  }
  return arr;
}

const needToSpecify = (response) => {

 
  if(response[0]){

    let count = Object.keys(response).length 
    let states = response.map( location => location.address.state);
    let arr = unique(states);

    let state_test = response[0].place_rank === 8; // Confirm that all states and territories are place_rank == 8

    if(arr.length < 3 || count < 4 || state_test) {
      return false;
    }
  }
    return true;
}


const nominatimSearch = (terms) => {

	const info = { term: terms }

	return new Promise((resolve, reject) => {
    axios.post( `${ROOT_URL}` + '/search/nominatim', info)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log("nominatum api error:" + error);
        reject(error);
      });
  });
};

const dbSearch = (location) => {
    return new Promise((resolve, reject) => {
      axios.post(`${ROOT_URL_DB}` + '/search', location)  
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log("server error:" + "Search Error");
        reject(error);
      });
    });
  };

const tribalSearch = (terms, isLinked = false) => {

  const info = { term: terms, isLinked: isLinked }

  return new Promise((resolve, reject) => {
    axios.post( `${ROOT_URL}` + '/search/tribal', info)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function searchGeography(term, isLinked=false) {

  var location = {
    city: "not found",
    county: "not found",  //might not need this, depending on how jurisdiction algorithm is set up.
    state: "not found",
  }

    return (dispatch) => {

        dispatch( { type: 'SHOW_LOADING', payload: [] });

        tribalSearch(term,isLinked).then((response) => {
          if(typeof response[0] !== 'undefined') { // v2: move length call up to reduce number of API calls
            dispatch({ type: 'SHOW_GEOGRAPHY', payload: [response, term] }); // If HD info is in the database
            } 
         else {
          nominatimSearch(term).then((response) => {
          
            if (!needToSpecify(response)) {

              location["city"] = response[0].address.city || response[0].address.town || response[0].address.boundary || response[0].address.village || response[0].address.suburb  || response[0].address.administrative || response[0].address.hamlet || response[0].address.leisure
              location["county"] = response[0].address.county
              location["state"] = response[0].address.state
              location["type"] = response[0].type

              if ( response[0].address.state === 'New York' && ((typeof response[0].address.suburb !== 'undefined' && NYC.indexOf(response[0].address.suburb) >= 0) || (typeof response[0].address.municipality !== 'undefined' && NYC.indexOf(response[0].address.municipality >= 0)))) {
                location["city"] = 'New York';
              } 

              dbSearch(location).then((response) => {
                var healthdepartment = {}
                if (typeof response[0] != 'undefined') {    
                  //TO DO      
                  // ADD LOGIC: what to do if more than one location response (ex. "queens" and "queensbury" situation). Put into dbSearch itself??
                  dispatch({ type: 'SHOW_GEOGRAPHY', payload: [response, term] }); // If HD info is in the database
                } else {
                    dispatch({ type: 'MISSING_HD', payload: [healthdepartment, term] });
                } 
              }); 
            } else if (response[0]){
              dispatch({ type: 'SPECIFY', payload: [location, term] }); //If search term is invalid
            } else {
              dispatch({ type: 'MISSING_GEOGRAPHY', payload: [location, term] }); //If search term is invalid
            }
        }).catch((error) => {
            console.log(`There was error: ${error} in searching the geography`);
          });
      }
    }).catch((error) => {
      console.log(`There was an internal error: ${error}`);
    });
  };
};


export function handleLetter(){
  return(dispatch) => {
      dispatch({ type: "CHANGE_LETTER_MODAL", payload: {} })
  }
}

export function setSelectedResultIndex(index){
  return(dispatch) => {
      dispatch({ type: "CHANGE_SELECTED_RESULT_INDEX", payload: index })
  }
}