import React, { Component } from 'react';
import { Form, FormControl, InputGroup, Button} from 'react-bootstrap';
import { searchGeography } from '../../redux/search/search.actions';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import './search-bar.scss';
import { LinkContainer } from "react-router-bootstrap";

class SearchBar extends Component {
    constructor(props) {
          super(props);
  
          this.state = {
              searchterm: this.props.searchTerm
            };
          this.onInputChange = this.onInputChange.bind(this);
          this.onEnter = this.onEnter.bind(this);
    }
    
    componentDidUpdate(prevProps){
        if (this.props.searchTerm !== prevProps.searchTerm){
        
                this.setState({
                    searchterm: this.props.searchTerm
                })
            }
      }
    
    onInputChange(event){
		this.setState({ searchterm: event.target.value });
    }
    
    onEnter(target){
        if(target.charCode===13){
            target.preventDefault();
            this.props.searchGeography(this.state.searchterm);
        }
    }
        render() {
            var message = "search-panel-message"

            
            if(this.props.showGeography === 1){
                message = "hd-info-card"
            } else if ( this.props.showGeography === 3){
                message = "alert-message"
            } else if ( this.props.showGeography === 2) {
                message = "misspelled-location-message"
            }
            
            return(
                    <Form inline className="search-bar">
                        <InputGroup>
                            <FormControl
                                onChange={this.onInputChange}
                                onKeyPress={this.onEnter}
                                value={this.state.searchterm}
                                type="text"
                                aria-label="Search"
                                aria-describedby={message}
                                aria-invalid={this.props.showGeography === 2 ? "true" : "false"}
                                placeholder={ this.props.translate("search-bar.placeholder") }
                            />
                            <LinkContainer to="/search"> 
                                <Button 
                                    variant="primary"
                                    active
                                    id="search-button"
                                    size="sm"
                                    type="submit"
                                    role="button"
                                    aria-label="Search a location to find TB program guidance."
                                    onClick={()=>{this.props.searchGeography(this.state.searchterm)}}
                                    >{ this.props.translate("search-bar.search") }
                                </Button>
                            </LinkContainer>
                        </InputGroup>
                    </Form>

            );
        };
    };

const mapStateToProps = state => ({
    translate: getTranslate(state.localize),
    searchTerm: state.search.searchTerm,
    showGeography: state.search.showGeography,
})

 export default connect(mapStateToProps, { searchGeography })(SearchBar);
