import React from 'react';
import { connect } from 'react-redux';
import "./guidance.scss";
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { Col, Card } from 'react-bootstrap';
import GuidancePanel from '../guidance-panel/guidance-panel.component';
import { useMediaQuery } from 'react-responsive'
import HDMap from '../map/map.component';

import Info from '../../img/address-card.svg';
import Vial from '../../img/vial-solid.svg';
import Phone from '../../img/phone_01.svg';


const Guidance = ({ currentSearches, translate, activeLanguage, selectedResultIndex }) => {

  const currentSearch = currentSearches[selectedResultIndex];

  const phone = useMediaQuery({
    query: '(max-width: 900px)'
  })

  return(
  <Col xs={{order: 1}}  sm={{order: 1}}  md={{order: 1}} lg={{order:12}}>

  { currentSearch && (
    <Card className="map-address" aria-hidden="true" >
        <Card.Body>
          <address className="contact">
            <Card.Text> {currentSearch.tb_program} </Card.Text>
            <Card.Text> {currentSearch.address}</Card.Text>
            <Card.Text> {currentSearch.city}, {currentSearch.state}, {currentSearch.zipcode} </Card.Text>
            <Card.Text> {currentSearch.phone} </Card.Text>
            <a href={'mailto::' + currentSearch.email}>{currentSearch.email}</a>
          </address>
        </Card.Body>
      </Card>) }
  
      {currentSearch.longitude && <HDMap />}

        { currentSearch.guidance_payment && currentSearch.guidance_payment.body &&
          (
            <GuidancePanel
                icon={Info}
                altText="Health information icon"
                question={phone ? translate("guidance.questions.payment.short") : translate("guidance.questions.payment.long")}
                response={ activeLanguage.code === "es" && currentSearch.guidance_payment.body_spanish ? currentSearch.guidance_payment.body_spanish : currentSearch.guidance_payment.body }
                link={ activeLanguage.code === "es" && currentSearch.guidance_payment.url_spanish ? currentSearch.guidance_payment.url_spanish : currentSearch.guidance_payment.url }
                linkText={translate("guidance.questions.payment.link")}
            />
          )}

        { currentSearch.guidance_services && currentSearch.guidance_services.body &&
          (
            <GuidancePanel
                icon={Vial}
                altText="Health services icon"
                question={ phone ? translate("guidance.questions.services.short") : translate("guidance.questions.services.long")}
                response={ activeLanguage.code === "es" && currentSearch.guidance_services.body_spanish ? currentSearch.guidance_services.body_spanish : currentSearch.guidance_services.body }
                link={ activeLanguage.code === "es" && currentSearch.guidance_services.url_spanish ? currentSearch.guidance_services.url_spanish : currentSearch.guidance_services.url }
                linkText={translate("guidance.questions.services.link")}
            />
          )}

        { currentSearch.guidance_symptoms && currentSearch.guidance_symptoms.body &&
          (
            <GuidancePanel
                icon={Phone}
                altText="Phone icon"
                question={phone ? translate("guidance.questions.symptoms.short") : translate("guidance.questions.symptoms.long")}
                response={ activeLanguage.code === "es" && currentSearch.guidance_symptoms.body_spanish ? currentSearch.guidance_symptoms.body_spanish : currentSearch.guidance_symptoms.body }
                link={ activeLanguage.code === "es" && currentSearch.guidance_symptoms.url_spanish ? currentSearch.guidance_symptoms.url_spanish : currentSearch.guidance_symptoms.url }
                linkText={translate("guidance.questions.symptoms.link")}
            />
          )}

      </Col>
  );  
        }


const mapStateToProps = state => ({
    currentSearches: state.search.currentSearch,
    translate: getTranslate(state.localize),
    activeLanguage: getActiveLanguage(state.localize),
    selectedResultIndex: state.search.selectedResultIndex || 0
  })


export default connect(mapStateToProps)(Guidance);