
import React, { Component } from 'react';
import SearchPage from './pages/search-page/search-page.component';
import CustomNavBar from "./components/nav-bar/nav-bar.component";
import globalTranslations from "./redux/translate/global.json";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Footer from "./components/Footer/Footer";
import { HashRouter } from "react-router-dom";
import { Switch, Route } from "react-router";
import { renderToStaticMarkup } from "react-dom/server";

import { withLocalize } from "react-localize-redux";

import './App.scss';

class App extends Component {

    constructor(props){
        super(props)

        this.props.initialize({
            languages: [
                {name: "English", code: "en" }, 
                {name: "Spanish", code: "es" }
            ], 
            translation: globalTranslations, 
            options: { renderToStaticMarkup }
        });
    }

    render() {
        return(
            <HashRouter>
                <div>
                    <CustomNavBar />
                    <Switch>
                        <Route exact path="/" component={SearchPage} />
                        <Route component={SearchPage} />
                     
                    </Switch>
                    
                </div>
                <div>
                <Footer/>
                </div>
            </HashRouter>
        );
    }
};

export default withLocalize(App);

