import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import './index.css';



import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';

import store from './redux/store';

ReactDOM.render(
  <Provider store= {store}>
    <LocalizeProvider store={store}>

        <BrowserRouter>
          <App />
        </BrowserRouter>

    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
);
