import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import "./related-guidance.scss";
import { searchGeography } from '../../redux/search/search.actions';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

const RelatedGuidance = ({ currentSearches, searchGeography, translate, selectedResultIndex }) => {

    const currentSearch = currentSearches[selectedResultIndex];

    if (typeof currentSearch.linked_locations !== 'undefined' && currentSearch.linked_locations !== "" && currentSearch.linked_locations !== " "){

        var array = currentSearch.linked_locations.split(",");
        for (var i = 0; i < array.length; i++) {
            array[i] = array[i].trim();
        }

        return(
            <Fragment>
                <p className="department-sections">{translate("health-department-card.additional-guidance-prompt")}</p>
                <div className="help-buttons">
                    { array.map((location, index) => (
                        <Button
                            key={index}
                            variant="outline-light"
                            size="md"
                            aria-label={"Click here for guidance on " + location}
                            onClick={() => {searchGeography(location,true)}}
                        >{location}
                        </Button>)
                    ) }
                </div>
            </Fragment>
        );

    } else {
        return(<Fragment />)
    }
}

const mapStateToProps = state => ({
    currentSearches: state.search.currentSearch,
    translate: getTranslate(state.localize),
    selectedResultIndex: state.search.selectedResultIndex || 0
  })


export default connect(mapStateToProps, { searchGeography })(RelatedGuidance);

