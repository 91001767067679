import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { Card, Col, Button } from "react-bootstrap";
import RelatedGuidance from '../related-guidance/related-guidance.component.jsx';
import Address from '../address/address.component.jsx';
import { handleLetter } from '../../redux/search/search.actions';
import LetterModal from "../letter-modal/letter-modal.component";
import Linkify from 'react-linkify';
import './department-info.scss';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} className="hyperlinks" target="_blank" rel="noopener noreferrer">
      {text}
    </a>
 );


const DepartmentInfo = ({ currentSearches, translate, handleLetter, showLetter, activeLanguage, selectedResultIndex }) => {
    
    const currentSearch = currentSearches[selectedResultIndex];

    const website = () => {
        if(activeLanguage.code === "es" && currentSearch.website_url_spanish){
            return (currentSearch.website_url_spanish)
        } else { 
            return (currentSearch.website_url) 
        }
    }
    const websiteRenderLink = (translation) => {
        return (translate(translation) + " ")
    }

    return (
            <Col xs={{order: 12}}  sm={{order: 12}}  md={{order: 12}} lg={{order:1}}>
                <Card text="light" aria-live="polite" id="hd-info-card">
                        <Card.Header as="h1">{ translate("health-department-card.header1")}</Card.Header>
                        <Card.Body id="welcome-card">
                            <Card.Title as="h2">{ translate("health-department-card.header2") + currentSearch.tb_program }</Card.Title>
                            { (currentSearch.comments || currentSearch.website_url) &&
                                <Fragment>
                                { currentSearch.comments && 
                                    <Linkify componentDecorator={componentDecorator}>
                                        <p className="department-sections">{ translate("health-department-card.header3") }</p> 
                                        <p>{activeLanguage.code === "es" && currentSearch.comments_spanish ? currentSearch.comments_spanish : currentSearch.comments }</p>
                                    </Linkify>}
                                { currentSearch.website_url && 
                                    <div className="link-button">
                                        <Button
                                            className="justify-content-center"
                                            variant="outline-light"
                                            size="md"
                                            aria-label="Visit health department website"
                                            target="_blank"
                                            href={ website().indexOf("http") === -1 ? "http://" + website(): website()}
                                            >
                                            <div id="weblink-text">
                                                {websiteRenderLink("health-department-card.website-link")}
                                                <span className="sr-only">external icon</span>
                                                <span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                            </div>
                                        </Button> 
                                    </div>}
                                    {currentSearch.longitude && (
                                        <div className="link-button">
                                        <Button
                                            className="justify-content-center" 
                                            variant="outline-light"
                                            size="md"
                                            aria-label="Find directions to this location."
                                            target="_blank"
                                            href={'https://www.google.com/maps/dir/?api=1&destination=' + currentSearch.latitude  + ',' + currentSearch.longitude}
                                        >
                                            <div id="weblink-text">
                                                {websiteRenderLink("right-output.directions-button")}
                                                <span className="sr-only">external icon</span>
                                                <span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                            </div>
                                        </Button>
                                        </div>)}
                            </Fragment>}
                            <RelatedGuidance />
                        </Card.Body>
                        <Card.Footer>
                            <div id="department-buttons">
                                <Button className="department-info-button" variant="link" onClick={() => {
                                    window.print()
                                }}>{translate("health-department-card.print")}</Button>
                                <Address />
                                {currentSearch?.file &&
                                <Button
                                    className="department-info-button" 
                                    variant="link"
                                    onClick={() => { handleLetter() }}
                                    >{translate("health-department-card.view-letter")}</Button>
                                }
                                    { showLetter && <LetterModal /> }
                            
                            </div>
                            <small>{translate("health-department-card.last-updated") + currentSearch.date_updated}</small>
                        </Card.Footer>
                </Card>
            </Col>
    )
}

const mapStateToProps = state => ({
    currentSearches: state.search.currentSearch,
    translate: getTranslate(state.localize),
    showLetter: state.search.showLetter,
    activeLanguage: getActiveLanguage(state.localize),
    selectedResultIndex: state.search.selectedResultIndex || 0
  })


export default connect(mapStateToProps, { handleLetter })(DepartmentInfo);