import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import './disclaimer.scss';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

const Disclaimer = ( { translate }) => {

    return(
        <Row className="align-items-center justify-content-center flex-fill">
            <Col md="auto">
                <Card bg="secondary" id="disclaimer-text">
                    <Card.Body>{ translate("cdc-disclaimer") }</Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
  })

export default connect(mapStateToProps)(Disclaimer);