import React from 'react';
import { Image, Card, Accordion, Button} from 'react-bootstrap';
import './guidance-panel.scss'
import Linkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} className="hyperlinks" target="_blank" rel="noopener noreferrer">
      {text}
    </a>
 );


const GuidancePanel = ({ icon, question, response, link, linkText, altText }) => {
    const linkTextRender = linkText + " ";

    return(
        <Accordion>
            <Card>
                <Card.Header as="h3" className="guidance-panel fas fa-angle-i float-right">
                    <Image alt= { altText } src={ icon } fluid />
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">                    
                            { question } 
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body >
                    { response && 
                        <Card.Text>
                            <Linkify componentDecorator={componentDecorator}>
                                { response } 
                            </Linkify>
                        </Card.Text>
                    }
                    { link && 
                        <Card.Link className="dummy-link" target="_blank" href={ link.indexOf("http") === -1 ? "http://" + link : link }>
                            <div>
                                {linkTextRender}
                                <span className="sr-only">external icon</span>
                                <span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                            </div>
                        </Card.Link>
                    }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default GuidancePanel;