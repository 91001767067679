import React, { Fragment, Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
//import L from 'leaflet';
import { connect } from 'react-redux';
import "./map.scss";


const HDMap = ({ currentSearches, selectedResultIndex }) => {

    const currentSearch = currentSearches[selectedResultIndex];

     return (<div>
                 {currentSearch.longitude && 
                    <Map center={[currentSearch.latitude, currentSearch.longitude]} 
                        doubleClickZoom={false}
                        closePopupOnClick={false}
                        dragging={false}
                        zoomSnap={false}
                        zoomDelta={false} 
                        trackResize={false}
                        touchZoom={false}
                        scrollWheelZoom={false}
                        zoom={10}
                        >
                        <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        
                        <Marker position={[currentSearch.latitude, currentSearch.longitude]}>
                            
                        {/*
                            Remove Pop-up for 508 accessibility. 
                            <Popup>
                                {currentSearch.tb_program} <br /> {currentSearch.phone}
                            </Popup>
                        */}
                        </Marker>
                        
                </Map>
                }
        </div>)
}


const mapStateToProps = state => ({
    currentSearches: state.search.currentSearch,
    selectedResultIndex: state.search.selectedResultIndex || 0
  })


export default connect(mapStateToProps)(HDMap);