import React from 'react';
import { getTranslate } from 'react-localize-redux';

import { connect } from 'react-redux';
import "./right-output.scss";
import { Card, Col } from 'react-bootstrap';
import Loading from '../loading/loading-component';


const RightOutput = ({ showGeography, translate, loading}) => {
    return(
        <Col xs={{order: 1}} sm={{order: 1}} md={{order: 1}} lg={{order: 12}}  className="justify-content-center">
        
            { loading && (
                <Loading />
            )} 

            { (showGeography === 2) && !loading && (
                    <Card aria-live="polite" id="misspelled-location-message">
                        <Card.Body>
                            <Card.Text>{ translate("right-output.no-location") }</Card.Text>
                        <br />
                        <Card.Text>{translate("right-output.no-location-note")}</Card.Text>
                        <br />
                            <Card.Link className="dummy-link" target="_blank" href="https://www.cdc.gov/publichealthgateway/healthdirectories/index.html">{ translate("right-output.missing-hd.stlt-guidance-link") }</Card.Link>
                        </Card.Body>
                    </Card>
                )} 

            { (showGeography === 3) && !loading && (
                    <Card role="alert" id="alert-message">
                        <Card.Body>
                            <Card.Text>{ translate("right-output.missing-hd.message") }</Card.Text>
                            <br />
                            <Card.Text>{ translate("right-output.missing-hd.message-note") }</Card.Text>
                            <br />
                            <p id="general-links">
                                <Card.Link className="dummy-link" target="_blank" href="https://www.cdc.gov/publichealthgateway/healthdirectories/index.html">{ translate("right-output.missing-hd.stlt-guidance-link") }</Card.Link>
                            </p>
                        </Card.Body>
                    </Card>
                )}
        </Col>            
    )
}

const mapStateToProps = state => ({
    showGeography: state.search.showGeography,
    translate: getTranslate(state.localize),
    loading: state.search.loading,
  })


export default connect(mapStateToProps)(RightOutput);
