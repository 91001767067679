//import letter_english from '../../images/EXAMPLE_LETTER_ENGLISH.png';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { getActiveLanguage } from 'react-localize-redux';
import { handleLetter }  from '../../redux/search/search.actions';
import './letter-modal.scss';


const LetterModal = ({ currentLanguage, currentSearches, handleLetter, selectedResultIndex }) => {
 
  const currentSearch = currentSearches[selectedResultIndex];

  const images = require.context('../../images/', true);

  let letter = images('./' + 'EXAMPLE_LETTER_' + currentLanguage + '.png')
  let url = ''
  //console.log(this.state.jurisdiction)
  if(currentSearch?.file)
  {
      let uint8 = new Uint8Array(currentSearch.file.data.data)
      //let file = new File([uint8], { type: this.state.jurisdiction.file.contentType })
      const blob = new Blob([uint8], { type: currentSearch.file.contentType });

      url = URL.createObjectURL(blob)
  } 

  // Replace the <img> src with the URL from our S3 bucket. See https://medium.com/swlh/how-to-mock-aws-services-in-local-development-e231852e8a0f for more information about API.
  return(
    <Modal show={true} centered={true} 
    //size = "lg"
    dialogClassName = "docwidth"
           onHide={() => { handleLetter()} }>
        <Modal.Header closebutton>
          <Modal.Title>
            {/* <Button
                onClick={() => window.print()}
            >Print Letter</Button> */}
          </Modal.Title>
        </Modal.Header>
      <Modal.Body>
          {/* <img className="letter" alt="Letter from TB program" src={letter}/> */}
          <iframe src= {url} type="application/pdf" width = "100%" height="600px"></iframe>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.localize).name,
  currentSearches: state.search.currentSearch,
  selectedResultIndex: state.search.selectedResultIndex || 0
})

export default connect(mapStateToProps, { handleLetter })(LetterModal);


