import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import "./address.scss";
import { getTranslate } from 'react-localize-redux';
import { Card, Modal, Button } from "react-bootstrap";

const Address = ({ currentSearches, translate, selectedResultIndex }) => {
  
      const currentSearch = currentSearches[selectedResultIndex];

      const [show, setShow] = useState(false);
    
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    
      return (
        <Fragment>
          <Button className="department-info-button" variant="link"
            onClick={handleShow}
          >{ translate("health-department-card.contact")}</Button>
    
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{ translate("health-department-card.contact-modal-header")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="address-box">
                { currentSearch && (
                    <Card id="address" aria-label="Information about the health department.">
                        <Card.Body>
                          <address className="contact">
                            <Card.Text> {currentSearch.tb_program} </Card.Text>
                            <Card.Text> {currentSearch.address}</Card.Text>
                            <Card.Text> {currentSearch.city}, {currentSearch.state}, {currentSearch.zipcode} </Card.Text>
                            <Card.Text> {currentSearch.phone} </Card.Text>
                            <a href={'mailto::' + currentSearch.email}>{currentSearch.email}</a>
                          </address>
                        </Card.Body>
                      </Card>) }
              </div>
            </Modal.Body>
          </Modal>
        </Fragment>
      );
    }
    
        
const mapStateToProps = state => ({
    currentSearches: state.search.currentSearch,
    translate: getTranslate(state.localize),
    selectedResultIndex: state.search.selectedResultIndex || 0
  })


export default connect(mapStateToProps)(Address);