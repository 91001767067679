const INITIAL_STATE = {
    currentSearch: [], 
    loading: false,
    searchTerm: '',
    showLetter: false,
    showGeography: 0,   //Show Geography: 0. do not show, 
                        //      1. show map and address, 
                        //      2. show missing geography message
                        //      3. show missing health department message
    selectedResultIndex : 0                 
};

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_GEOGRAPHY': 
            return {
                ...state,
                currentSearch: action.payload[0], 
                showGeography: 1,
                searchTerm: action.payload[1],
                loading: false,
            }

        case 'MISSING_GEOGRAPHY':
            return{
                ...state, 
                currentSearch: [action.payload[0]], 
                showGeography: 2,
                searchTerm: action.payload[1],
                loading: false,
            }

        case 'MISSING_HD':
            return{
                ...state, 
                currentSearch: [action.payload[0]], 
                showGeography: 3,
                searchTerm: action.payload[1],
                loading: false,
            }

        case 'SHOW_LOADING': 
            return{
                ...state, 
                loading: true,
            }
        
        case 'SPECIFY': 
            return{
                ...state,
                currentSearch: [action.payload[0]],
                showGeography: 4,
                searchTerm: action.payload[1],
                loading: false,
            }

        case 'CHANGE_LETTER_MODAL':
            return{
                ...state, 
                showLetter: !state.showLetter,
                loading: false,
            }
        
        case 'CHANGE_SELECTED_RESULT_INDEX':
            return{
                ...state, 
                selectedResultIndex: action.payload,
            }
    
        default: 
            return state;
    }
}


export default searchReducer;